.nbt-outer {
  padding: 8px;
  padding-top: 2px;
  width: 100%;
  min-height: 450px;
  height: 100%;
}

.nbt-gallery {
  overflow-y: auto;
  min-height: 280px;
  background-color: #202020;
  max-height: calc(100vh - 280px);
}

.nbt-projectGallery {
  height: 360px;
  overflow-y: auto;
}

.nbt-selectedProject {
  padding-top: 20px;
  padding-left: 30px;
}

.nbt-optionsArea {
  padding-bottom: 10px;
}
