.sa-outer {
  padding: 2px;
  padding-top: 0px;
  vertical-align: middle;
  padding-left: 10px;
  display: grid;
  grid-template-columns: 1fr 30px;
  height: 100%;
  max-width: calc(100vw);
}

.sa-singleline {
  padding-bottom: 6px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: clip;
  max-width: calc(100vw - 42px);
  display: inline-block;
}

.sa-placeHolder {
  padding-top: 2px;
  padding-left: 2px;
  display: grid;
  grid-template-columns: 16px 1fr;
}

.sa-placeHolderIcon {
  padding-top: 3px;
  grid-column: 1;
}

.sa-placeHolderText {
  padding: 4px 0px 0px 5px !important;
  height: 24px !important;
  box-shadow: none !important;
  margin-left: 5px;
  text-align: left I !important;
  justify-content: left !important;
  grid-column: 2;
}

.sa-serchplaceHolder {
  border: solid 0px !important;
  background-color: transparent !important;
  padding: 3px 0px 0px 5px !important;
  height: 24px !important;
  box-shadow: none !important;
  margin-left: 5px;
  text-align: left I !important;
  justify-content: left !important;
  grid-column: 2;
}

.sa-inputArea {
  display: grid;
  width: 100%;
  grid-template-columns: 40px 1fr;
}

.sa-loading {
  width: 16px;
  margin-top: 3px;
  margin-right: 6px;
}

.sa-message {
  position: relative;
  top: 6px;
}

.sa-inputButton {
  grid-column: 1;
  padding-top: 1px;
}

.sa-inputButton BUTTON {
  max-width: 38px;
  padding: 0px;
  min-width: 38px;
  height: 28px;
}

.sa-inputEditor {
  grid-column: 2;
}

.sa-messageOuter {
  grid-column: 1;
  vertical-align: top;
  cursor: pointer;
  padding-top: 2px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: clip;
  white-space: nowrap;
  max-width: calc(100vw - 42px);
}

.sa-tools {
  grid-column: 2;
  vertical-align: middle;
  padding-top: 2px;
}

.sa-listOuter {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
}

.sa-list {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: calc(100vw - 42px);
}

.sa-list LI {
  min-height: 1.25rem;
  white-space: nowrap;
}

.sa-list .ui-list__itemcontent {
  font-size: small;
}

.sa-list li {
  padding-left: 3px;
}
