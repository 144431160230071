.nepro-outer {
  padding: 8px;
  padding-top: 2px;
  width: 100%;
  min-height: 450px;
  height: 100%;
}

.nepro-newCreator {
  grid-row: 2;
  grid-column: 1;
  padding-top: 5px;
}

.nepro-newCreatorInput {
  grid-row: 2;
  grid-column: 2;
}

.nepro-newName {
  grid-row: 1;
  grid-column: 1;
  padding-top: 5px;
}

.nepro-newNameInput {
  grid-row: 1;
  grid-column: 2;
}

.nepro-newShortName {
  grid-row: 3;
  grid-column: 1;
  padding-top: 5px;
}

.nepro-newShortNameInput {
  grid-row: 3;
  grid-column: 2;
}

.nepro-newDescription {
  grid-row: 4;
  grid-column: 1;
  padding-top: 5px;
}

.nepro-newDescriptionInput {
  grid-row: 4;
  grid-column: 2;
}

.nepro-dialog {
  display: grid;
  grid-template-rows: 50px 50px 50px 66px 50px 1fr;
  grid-template-columns: 140px 1fr;
  padding-bottom: 15px;
  padding-top: 20px;
}

.nepro-newFolder {
  padding-top: 35px;
  grid-row: 6;
  grid-column: 1;
}

.nepro-newPath {
  padding-top: 35px;
  grid-row: 6;
  grid-column: 2;
}

.nepro-newDescriptionInput {
  grid-row: 4;
  grid-column: 2;
}

.nepro-newDescriptionInput textarea {
  width: 344px;
}

.nepro-newTrack {
  grid-row: 5;
  grid-column: 1;
}

.nepro-newTrackInput {
  grid-row: 5;
  grid-column: 2;
  padding-bottom: 20px;
  margin-right: 4px;
}

.nepro-newTrackInput .ui-dropdown__container {
  width: 345px;
}
