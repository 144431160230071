@media only screen and (min-width: 1100px) {
  .pid-toolArea {
    display: grid;
    grid-template-columns: 280px 50px 230px 1fr;
    margin-bottom: 1px;
  }

  .pid-suiteDropdown .ui-dropdown__container {
    width: 210px;
  }
}

@media only screen and (max-width: 1100px) {
  .pid-toolArea {
    display: grid;
    grid-template-columns: 90px 50px 165px 1fr;
    margin-bottom: 1px;
  }

  .pid-suiteDropdown .ui-dropdown__container {
    width: 165px;
  }
}

.pid-outer {
  height: 100%;
  text-align: left;
}

.pid-header {
  font-size: medium;
  margin-bottom: 10px;
}

.pid-title {
  font-size: 16pt;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

.pid-filterToolbar {
  margin-bottom: 1px;
}
.pis-contentSummaryHeader {
  padding: 10px;
}

.pis-contentSummaryArea {
  display: grid;
  padding: 10px;
}

.pis-itemHeadCell {
  grid-row: 1;
  font-weight: bold;
}

.pis-itemSummName {
  grid-column: 1;
}

.pis-itemSummCt {
  grid-column: 2;
}

.pis-itemDataSummTotal {
  grid-column: 3;
}

.pis-itemDataSummMax {
  grid-column: 4;
}

.pis-itemDataSummAvg {
  grid-column: 5;
}

.pis-itemDataSummMin {
  grid-column: 6;
}
.pid-summaryArea {
  overflow-y: auto;
}

.pis-summaryArea {
  display: grid;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: small;
  grid-template-columns: 500px 1fr;
}

.pis-image {
  background-size: cover;
  width: 64px;
  height: 64px;
}

.pis-itemHeader {
  grid-column: 1;
  font-weight: bold;
}

.pis-itemData {
  grid-column: 2;
}

.pid-area {
  display: table;
  width: 100%;
  height: 100%;
  font-size: small;
  overflow: hidden;
}

.pid-header {
  padding-left: 10px;
  padding-top: 10px;
}

.pid-headerRow {
  display: table-row;
}

.pid-headerCell {
  display: table-cell;
  padding: 10px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: smaller;
}
.pid-headerTypeCell {
  max-width: 37px;
  width: 37px;
}

.pid-topToolbar {
  grid-column: 1;
}

.pid-suiteTitle {
  grid-column: 2;
  padding-top: 5px;
}

.pid-suiteDropdown {
  grid-column: 3;
}

.pid-actionToolbar {
  grid-column: 4;
}

.pid-validating {
  padding: 20px;
  padding-top: 19px;
}
