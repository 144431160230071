.micb-outer {
  display: inline-flex;
}

.micb-button {
  border: 0px !important;
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  background-color: inherit !important;
  box-shadow: none !important;
  padding-right: 0px !important;
  text-align: left !important;
  vertical-align: inherit !important;
  padding-left: 0px !important;
}

.micb-button .bi {
  height: 100% !important;
}

.micb-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 3px 1fr 3px;
  grid-template-rows: 3px 1fr 3px;
  border: solid 2px;
}

.micb-edge {
  font-size: 1pt;
  font-family: sans-serif;
}

.micb-7 {
  grid-column: 1;
  grid-row: 1;
}

.micb-8 {
  grid-column: 2;
  grid-row: 1;
}

.micb-9 {
  grid-column: 3;
  grid-row: 1;
}

.micb-4 {
  grid-column: 1;
  grid-row: 2;
}

.micb-5 {
  grid-column: 2;
  grid-row: 2;
}

.micb-6 {
  grid-column: 3;
  grid-row: 2;
}

.micb-1 {
  grid-column: 1;
  grid-row: 3;
}

.micb-2 {
  grid-column: 2;
  grid-row: 3;
}

.micb-3 {
  grid-column: 3;
  grid-row: 3;
}
