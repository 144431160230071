body {
  margin: 0;
  font-family: "Noto Sans", "Segoe UI", "Helvetica Neue", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

input[type="file"] {
  padding: 6px;
  border: solid 1px rgb(96, 94, 92);
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem;
}

input[type="file"]:hover {
  padding: 6px;
  border-color: rgb(96, 94, 92);
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem;
}

.ui-input__input {
  padding-right: 5px !important;
}

.ui-checkbox__label {
  padding-top: 2px;
}

.ui-dialog {
  border-radius: 0px;
  max-width: calc(100vw - 8px);
  margin: 4px;
  width: calc(100vw - 40px) !important;
  max-width: 900px !important;
}

.ui-dialog__content {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

BUTTON {
  border-radius: 0px !important;
}

@media screen and (prefers-contrast: more) {
  .ui-splitbutton__toggle {
    border: solid 1px !important;
  }
}
