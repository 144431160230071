@media only screen and (min-width: 800px) {
  .pg-binWrap {
    margin: 10px;
    margin-top: 0px;
    padding-bottom: 10px;
    border-left: solid 1px;
    border-right: solid 1px;
    border-bottom: solid 1px;
    border-top: solid 0px;
  }

  .pg-tabArea {
    display: block;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .pg-binWrap {
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: solid 0px;
    border-right: solid 0px;
    border-bottom: solid 1px;
    border-top: solid 0px;
    text-align: center;
  }

  .pg-binWrap-small {
    border-top: solid 1px;
  }

  .pg-tabArea {
    display: block;

    padding-top: 10px;
    padding-left: 5px;
    padding-bottom: 0px;
  }
}

.pg-binWrap-empty {
  padding: 4px 10px 10px 10px;
}

.pg-outer {
  padding-bottom: 10px;
}

.pg-notFound {
  padding-top: 6px;
}

.pg-tabButton {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  box-shadow: none !important;
  height: 32px;
  padding: 20px !important;
}

.pg-tabsTop {
  width: 100%;
  display: grid;
  margin-right: 28px;
  grid-template-columns: 376px 1fr;
}

.pg-underline {
  display: inline-block;
  height: 32.5px;
  width: 6px;
  grid-column: 1;
  border-bottom: solid 1px;
}

.pg-tabsFiller {
  display: inline-block;
  height: 51px;
  margin-right: 10px;
  grid-column: 2;
  border-bottom: solid 1px;
}
