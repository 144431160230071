@media only screen and (min-width: 800px) {
  .pt-outer {
    margin-left: 10px;
    margin-top: 12px;
    width: 340px;
    min-height: 191px;
    max-width: calc(100vw - 60px);
    display: inline-block;
    border: solid 2px #202020;
    text-align: left;
  }

  .pts-outer {
    padding-top: 10px;
    padding-left: 10px;
    display: inline-block;
  }
}

@media only screen and (max-width: 800px) {
  .pt-outer {
    margin-top: 12px;
    width: 340px;
    margin-left: 6px;
    margin-right: 6px;
    min-height: 191px;
    max-width: calc(100vw - 60px);
    display: inline-block;
    border: solid 2px #202020;
    text-align: left;
  }

  .pts-outer {
    padding-top: 10px;
    display: inline-block;
  }
}

.pt-grid {
  display: grid;
  height: 100%;
  grid-template-columns: 56px 1fr;
  grid-template-rows: 59px 84px 45px;
}

.pt-mainArea {
  grid-row: 1;
  grid-column-start: 2;
  grid-column-end: 3;
  padding-top: 7px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 6px;
}

.pt-mainArea-long {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-top: 7px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 6px;
}

.pt-iconArea {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 6px;
}

.pt-iconBorder {
  border: inset 1px #848586;
  min-height: 50px;
  max-height: 50px;
  min-width: 50px;
  max-width: 50px;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}

.pt-imageTile {
  width: 48px;
  height: 48px;
  opacity: 0.6;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
}

.pts-imageTile {
  width: 32px;
  height: 32px;
  opacity: 0.6;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
}

.pt-descriptionArea {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  min-height: 50px;
  padding-left: 8px;
  padding-right: 6px;
}

.pt-mini-toolbar {
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  vertical-align: middle;
  text-align: right;
  border-top: inset 1px #848586;
  vertical-align: top;
  padding: 0px;
}

.pt-mini-toolbar button {
  border: 0px;
  background-color: transparent;
  border-left: inset 1.5px #848586;
  box-shadow: none;
  width: 60px;
  height: 42px;
  padding: 0px;
  margin: 0px;
}

.pt-mini-toolbar button span {
  display: block;
  border-left: outset 2px #848586;
  width: 95px;
  height: 40px;
  padding-top: 11px;
}

.pt-mini-toolbar-interior {
  width: 100%;
  height: 100%;
  border-top: outset 2px #848586;
}

.pt-title {
  font-weight: bold;
  padding-top: 1px;
  display: inline;
  text-overflow: ellipsis;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 22px;
  font-size: medium;
}

.pt-ghpath {
  padding-bottom: 8px;
}

.pt-ghlogo {
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.pt-ghpath a {
  color: white;
  font-size: small;
}

.pts-button {
  width: 360px;
  height: 138px;
  max-width: calc(100vw - 46px);
  border: solid 2px #202020;
}

.pts-outer-selected {
  background-color: green;
}

.pts-grid {
  display: grid;
  height: 100%;
  cursor: pointer;
  text-align: left;
  grid-template-rows: 88px 1px 50px;
}

.pts-tag {
  text-transform: uppercase;
  font-size: x-small;
  margin-left: 7px;
  margin-top: 1px;
  border: solid 1px;
  position: relative;
  top: -1px;
  padding: 2px 6px 3px 4px;
}

.pts-mainArea {
  grid-row: 1;
  grid-column: 1;
  padding: 3px;
  padding-top: 3px;
  vertical-align: middle;
  word-wrap: break-word;
}

.pts-code-line {
  white-space: nowrap;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  overflow-x: hidden;
  font-size: small;
}

.pts-title {
  font-weight: bold;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: inline;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 320px;
  padding-left: 5px;
  padding-top: 8px;
  max-width: 320px;
}

.pts-iconArea {
  grid-column: 1;
  margin-top: 3px;
  margin-left: 3px;
  border-left: inset 1px #848586;
  border-top: inset 1px #848586;
  border-right: inset 1px #848586;
  border-bottom: inset 1px #848586;
}

.pts-descriptionArea {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 3px;
  height: 60px;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 8px;
  font-size: small;
}

.pt-docLink {
  display: inline-block;
}

.pts-code-topic {
  padding-left: 4px;
}
