.fexfo-area {
  width: 100%;
}

.fexfo-bin {
}

.fexfo-binIndent {
  padding-left: 23px;
}
