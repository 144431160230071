.etre-area {
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
  max-width: calc(100% - 2px);
  overflow-y: auto;
}

.etre-toolBarArea {
  width: 100%;
  padding-top: 3px;
  padding-left: 29px;
  height: 34px;
}

.etre-headerTransition {
  background-position-x: left;
  background-repeat: repeat-x;
  height: 40px;
}

.etre-header {
  font-size: 16pt;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 13px;
}

.etre-form {
  padding-top: 10px;
  padding-left: 20px;
}

.etre-rc-header {
  padding-left: 20px;
  padding-top: 10px;
}

.etre-header-interior {
  font-size: large;
}
