.fex-area {
  width: 100%;
  height: 100%;
  min-height: 200px;
  overflow: auto;
  border: solid 1px;
  padding: 2px;
}

.fex-folderArea {
  padding: 4px;
  min-height: 120px;
}

.fex-newFolderIcon {
  padding: 6px 6px 8px 2px;
  grid-column: 1;
  vertical-align: middle;
}

.fex-newFolderArea {
  display: grid;
  margin-left: 2px;
  padding: 8px 4px 8px 4px;
  grid-template-columns: 26px 1fr 194px 100px;
}

.fex-newFolderLabel {
  grid-column: 2;
  overflow-x: hidden;
  padding-top: 6px;
}

.fex-newFolderName {
  grid-column: 3;
}

.fex-newFolderGo {
  grid-column: 4;
}
