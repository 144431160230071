.tui-grid-container {
  font-family: "Noto Sans", "Segoe UI", "Helvetica Neue", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif !important;
}

.ge-outer {
  image-rendering: pixelated;
}

.ge-image {
  padding: 6px;
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-size: contain;
}

.ge-float {
  position: absolute;
  margin: 8px;
  z-index: 100;
}

.ge-float BUTTON {
  padding-left: 0px;
  padding-right: 0px;
  width: 36px;
  min-width: inherit;
}
