@media only screen and (min-width: 860px) {
  .pe-meta {
    padding: 10px 4px 8px 11px;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
    text-align: right;
    grid-row: 1;
    grid-column: 4;
  }
}
@media only screen and (max-width: 860px) {
  .pe-meta {
    display: none;
  }
}

.pe-menuIcon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  position: relative;
  left: -2px;
  top: -1px;
  image-rendering: pixelated;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.pe-singleDragOver {
  position: fixed;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  background-color: rgba(48, 96, 48, 0.9);
  border: solid 3px rgba(48, 96, 48, 1);
  vertical-align: middle;
  text-align: center;
  margin: 10px;
  padding-top: calc(50vh - 20px);
  z-index: 10000;
  opacity: 0.9;
}

.pe-zoneDragOver {
  position: fixed;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  vertical-align: middle;
  text-align: center;
  margin: 10px;
  z-index: 10000;
  opacity: 0.9;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.pe-dragZone1 {
  grid-row: 1;
  background-color: rgba(48, 96, 48, 0.8);
  border: solid 6px rgba(48, 96, 48, 1);
  vertical-align: middle;
  margin: 10px;
  padding-top: calc(25vh - 20px);
}

.pe-dragZone1:hover {
  background-color: rgba(64, 112, 64, 0.8);
  border: solid 6px rgba(64, 112, 64, 1);
}

.pe-dragZone2 {
  grid-row: 2;
  background-color: rgba(48, 96, 48, 0.8);
  border: solid 6px rgba(48, 96, 48, 1);
  vertical-align: middle;
  padding-top: calc(25vh - 20px);
  margin: 10px;
}

.pe-dragZone2:hover {
  background-color: rgba(64, 112, 64, 0.8);
  border: solid 6px rgba(64, 112, 64, 1);
}

.pe-gridOuter {
  max-width: 100vw;
  min-width: 100vw;
  display: grid;
}

.pe-gridOuterCollapsed {
  grid-template-rows: 50px 1fr 40px;
}

.pe-gridOuterExpanded {
  grid-template-rows: 50px 1fr 240px;
}

.pe-gridOuterNtbCollapsed {
  grid-template-rows: 0px 1fr 0px;
}

.pe-title {
  max-width: 275px;
  width: 275px;
  padding-top: 4px;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pe-metaProjectTitle {
  font-weight: bold;
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pe-signin {
  width: 40px;
  padding-left: 4px;
  grid-column: 2;
}

.pe-toolbar {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 1;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  vertical-align: middle;
}

.pe-toolbar .label {
  max-width: 180px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-top: 1px;
  display: inline-block;
}
.pe-toolbar .label-arrowouter {
  padding-top: 4px;
  display: inline-block;
}

.pe-toolbar-compact {
  grid-column-start: 1;
  grid-column-end: 5;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  vertical-align: middle;
}

.pe-toolbar .al {
  height: 3rem;
}

.pe-toolbar-compact .al {
  height: 3rem;
}

.pe-itemeditor {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 8px;
  width: calc(100vw - 300px);
  max-width: calc(100vw - 300px);
  min-width: calc(100vw - 300px);
}

.pe-itemlist {
  grid-row-start: 2;
  grid-row-end: 3;
}

.pe-col1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row: 2;
}

.pe-col1and2 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
}

.pe-col2 {
  grid-column-start: 2;
  grid-column-end: 3;

  grid-row: 2;
}

.pe-itemSplitter {
  cursor: ew-resize;
  font-size: 1pt;
  border-top: "2px outset";
  border-bottom: "2px outset";
}

.pe-col3and4 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 2;
}

.pe-colAll {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 2;
}

.pe-col3 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row: 2;
}

.pe-col4 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row: 2;
}

.pe-statusbar {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 5;
  min-height: 44px;
}
