.wsa-outer {
  padding-top: 10px;
}

.wsa-header {
  font-weight: bold;
  padding-left: 3px;
  padding-top: 6px;
  padding-bottom: 26px;
}

.wsa-grid {
  display: grid;
  grid-template-columns: 180px 1fr;
}

.wsa-gridCompact {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 30px;
  overflow-y: auto;
}

.wsa-label {
  padding-left: 2px;
  padding-bottom: 10px;
  padding-top: 6px;
  user-select: none;
}

.wsa-nameinput {
  grid-row: 1;
  grid-column: 2;
  padding-bottom: 20px;
  text-align: left;
}

.wsa-nameinput button {
  margin-left: 4px;
}

.wsa-nameinput input {
  width: 260px;
}

.wsa-namelabel {
  grid-row: 1;
  grid-column: 1;
}

.wsa-backupinput {
  grid-row: 2;
  grid-column: 2;
  padding-bottom: 20px;
}

.wsa-backupinput .ui-dropdown__container {
  width: 220px !important;
}

.wsa-backupinput button {
  margin-left: 4px;
  display: inline-block;
}

.wsa-seedinput input {
  width: 120px;
}

.wsa-packlabel {
  grid-row: 3;
  grid-column: 1;
}

.wsa-packs {
  grid-row: 3;
  grid-column: 2;
  padding-bottom: 20px;
}

.wsa-worldsettingslabel {
  grid-row: 7;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-top: 30px;
  padding-bottom: 20px;
  font-weight: 600;
}

.wsa-templatelabel {
  grid-row: 8;
  grid-column: 1;
}

.wsa-templates {
  grid-row: 8;
  grid-column: 2;
  padding-bottom: 20px;
}

.wsa-seedinput {
  grid-row: 10;
  grid-column: 2;
  padding-bottom: 20px;
  text-align: left;
}

.wsa-seedinput button {
  margin-left: 4px;
}

.wsa-seedinput input {
  width: 160px;
}

.wsa-seedlabel {
  grid-row: 10;
  grid-column: 1;
}

.wsa-iseditorinput {
  grid-row: 4;
  grid-column: 2;
  padding-bottom: 20px;
  text-align: left;
}

.wsa-iseditorlabel {
  grid-row: 4;
  grid-column: 2;
  padding-top: 2px;
}

.wsa-geninput {
  grid-row: 9;
  grid-column: 2;
  padding-bottom: 20px;
  text-align: left;
}

.wsa-genlabel {
  grid-row: 9;
  grid-column: 1;
}

.wsa-geninput button {
  margin-left: 4px;
}

.wsa-geninput .ui-dropdown__container {
  width: 220px !important;
}

.wsa-gametypelabel {
  grid-row: 5;
  grid-column: 1;
}

.wsa-gametypeinput .ui-dropdown__container {
  width: 220px !important;
}

.wsa-gametypeinput button {
  text-align: left;
}

.wsa-gametypeinput {
  grid-row: 5;
  grid-column: 2;
  padding-bottom: 20px;
}

.wsa-gametypeinput button {
  margin-left: 4px;
  display: inline-block;
}

.wsa-gametypeinput input {
  width: 120px;
}

.wsa-gamedifflabel {
  grid-row: 6;
  grid-column: 1;
}

.wsa-gamediffinput .ui-dropdown__container {
  width: 220px !important;
}

.wsa-gamediffinput button {
  text-align: left;
}

.wsa-gamediffinput {
  grid-row: 6;
  grid-column: 2;
  padding-bottom: 20px;
}

.wsa-gamediffinput button {
  margin-left: 4px;
  display: inline-block;
}

.wsa-gamediffinput input {
  width: 120px;
}

.wsa-backuplabel {
  grid-row: 2;
  grid-column: 1;
}
