.cs-outer {
}

.cs-panelArea {
  padding-top: 20px;
}

.cs-header {
  font-size: 16pt;
  padding-top: 15px;
  padding-left: 18px;
  padding-bottom: 16px;
  margin: 0px;
}

.cs-headerTransition {
  background-position-x: left;
  background-repeat: repeat-x;
  height: 40px;
}
