.pact-outer {
  padding-left: 10px;
  padding-top: 2px;
  overflow-y: auto;
}

.pact-binHeader {
  font-size: large;
  font-weight: bold;
  padding-top: 14px;
  line-height: 1;
}

.pact-toolTile {
  height: 110px !important;
  display: inline-flex !important;
  margin-left: 10px;
  padding: 0px !important;
  text-align: left;
}

.pact-toolTile-button {
  margin-top: 3px;
  margin-left: 0px !important;
  height: 35px !important;
}

.pact-toolTileInner {
  height: 100%;
  width: 320px;
  text-align: left;
  padding: 6px 8px 6px 8px;
}

.pact-toolTile input[type="file"] {
  box-shadow: none;
  margin-left: 5px;
  font-weight: 600;
  border-color: rgb(72, 73, 74);
}

.pact-toolTile-label {
  font-weight: bold;
  padding-bottom: 6px;
  display: grid;
  grid-template-columns: 30px 1fr;
}

.pact-toolTile-instruction {
  font-size: small;
  padding-bottom: 8px;
  height: 36px;
}

.pact-toolTile-button {
  margin-left: 4px;
  height: 35px !important;
  border: solid 1px rgb(72, 73, 74) !important;
  box-shadow: none !important;
}

.pact-tileDown {
  border: inset 3px !important;
}

.pact-faIconWrap {
  grid-column: 1;
  padding-top: 2px;
}

.pact-faIconWrapIn {
  grid-column: 1;
  padding-top: 2px;
  padding-left: 1px;
}

.pact-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  grid-column: 1;
  image-rendering: pixelated;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  margin-left: 0px;
  margin-top: 1px;
  border: solid 1px black;
}

.pact-label {
  grid-column: 2;
  padding-top: 4px;
}

.pact-tileBin {
  width: 100%;
}
