.pil-outer {
  border-top: inset 2px;
  border-bottom: inset 2px;
}

.pil-wrap {
  border-top: outset 2px;
  border-bottom: outset 2px;
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-template-rows: 46px 1fr;
}

.pil-expandButton {
  margin-top: 3px;
  padding-left: 6px;
  margin-left: 3px;
  width: 24px;
  height: 25px;
}

.pil-newarea {
  grid-column: 1;
  grid-row: 1;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 10px;
}

.pil-previewImage {
  height: 40px;
  grid-column: 1;
}

.pil-deleteWarning {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 20px;
}

.pil-inlineSource {
  display: inline-block;
}

.pil-commands {
  grid-column: 2;
  grid-row: 1;
  text-align: right;
  max-width: 140px;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 8px;
}

.pil-list {
  grid-column-start: 1;
  grid-column-end: 3;
  overflow-y: auto;
  overflow-x: hidden;
}

.pil-fixedLine {
  padding-left: 17px;
  font-size: 0.875rem;
  height: 32px;
  padding-top: 6px;
}

.pil-projectResults {
  padding-left: 17px;
}

.pil-fixedLineRow {
  height: 32px;
  display: grid;
  grid-template-columns: 1fr 32px;
  padding-top: 0px;
}

.pil-list LI * {
  margin-right: 0px;
}

.pil-list UL LI {
  padding-left: 0px;
  min-height: auto;
  padding-right: 0px;
}

.pil-item {
  display: grid;
  height: 36px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  grid-template-columns: 10px 1fr 30px 30px;
  font-size: 0.875rem;
}

.pil-itemTypeHeader {
  display: grid;
  height: 36px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  grid-template-columns: 24px 1fr 38px;
  font-size: 0.875rem;
}

.pil-pathHeader {
  display: grid;
  height: 36px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  grid-template-columns: 10px 20px 25px 1fr;
  font-size: 0.875rem;
}

.pil-itemTypeCollapsedToggle {
  grid-column: 1;
  padding: 8px;
}

.pil-headerLabel {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-template-columns: 1fr 80px;
  text-overflow: ellipsis;
  display: grid;
}

.pil-storagePathCollapsedToggle {
  grid-column: 2;
  padding: 8px;
}

.pil-storagePathIcon {
  grid-column: 3;
  padding-top: 8px;
  padding-left: 6px;
}

.pil-storagePathLabel {
  grid-column: 4;
  text-overflow: ellipsis;
}

.pil-itemTypeTag {
  grid-column: 1;
}

.pil-itemLabel {
  text-overflow: ellipsis;
  padding-left: 6px;
  display: block;
  height: 36px;
  padding-top: 1px;
}

.pil-itemIcon {
  background-size: cover;
  width: 30px;
  margin-top: 3px;
  height: 30px;
}

.pil-itemIndicator {
  border: solid 0px;
  grid-column: 4;
  line-height: 1;
  padding: 5px 5px 4px 4px;
  margin: 6px 0px 0px 1px;
  box-shadow: none;
  width: 24px;
  height: 25px;
}

.pil-itemIndicatorInterior {
  text-align: center;
}

.pil-itemIndicatorRO {
  border: solid 0px;
  grid-column: 4;
  line-height: 1;
  padding: 5px 5px 4px 4px;
  margin: 6px 0px 0px 1px;
  box-shadow: none;
  width: 24px;
  height: 25px;
  background-color: green;
  text-align: center;
}

.pil-cmbUnfocused button {
  opacity: 0;
}

.pil-stats {
  grid-column: 2;
  text-align: right;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-wrap: nowrap;
}

.pil-projectName {
  overflow-x: hidden;
  width: 100%;
  margin-right: 2px;
  text-overflow: clip;
  grid-column: 1;
  z-index: 90;
  padding-top: 6px;
  display: block;
  white-space: nowrap;
}

.pil-projectIcon {
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
  grid-column: 2;
  background-size: cover;
}

.pil-name {
  overflow-x: hidden;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-right: 2px;
  text-overflow: clip;
  grid-column: 1;
  z-index: 90;
  display: block;
  white-space: nowrap;
}

.pil-name-gh {
  padding-left: 20px;
}

.pil-typetag {
  font-size: x-small;
  text-transform: uppercase;
  border: solid 1px gray;
  border-radius: 2px;
  white-space: nowrap;
  float: right;
  top: 9px;
  left: 186px;
  background-color: rgba(120, 120, 120);
  position: absolute;
  text-align: center;
  min-width: 66px;
  margin-right: 4px;
  padding: 3px 1px 3px 1px;
  z-index: 100;
  line-height: 1;
}

.pil-dialog {
  min-height: 90px;
  margin-top: 20px;
  vertical-align: top;
}

.pil-extension {
  padding-left: 8px;
}
