.tui-image-editor-header {
  display: none;
}

.tui-image-editor-container .tui-image-editor-main {
  top: 0;
}

.tui-image-editor-container .tui-image-editor-help-menu {
  border-radius: 0px !important;
}

.tui-image-editor-container .tui-image-editor-controls-buttons div {
  border-radius: 0px !important;
}

.ie-outer {
  image-rendering: pixelated;
}

.ie-image {
  padding: 6px;
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
  background-repeat: no-repeat;
  background-size: contain;
}

.ie-float {
  position: absolute;
  margin: 8px;
  z-index: 100;
}

.ie-float BUTTON {
  padding-left: 0px;
  padding-right: 0px;
  width: 36px;
  min-width: inherit;
}
